<template>
    <section class="section">
        <div class="row py-5">
            <div class="col-md-6 offset-md-9 col-12 offset-6">
                <img src="/assets/logo-vertical.png" alt="ヒトツキ" />
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-md-10 offset-md-7">
                <h2 class="section-title justify-content-center text-primary">ログイン</h2>
            </div>
        </div>
        <form @submit.prevent="signin">
            <div class="row mb-3">
                <div class="col-md-8 offset-md-8">
                    <label class="form-label">企業コード</label>
                    <form-input v-model="credential.company_code" required="true"></form-input>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-8 offset-md-8">
                    <label class="form-label">メールアドレス</label>
                    <input type="email" class="form-control" v-model="credential.email" required="true">
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-8 offset-md-8">
                    <label class="form-label">パスワード</label>
                    <input type="password" class="form-control" v-model="credential.password" required="true">
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-8 offset-md-8">
                    <form-checkbox v-model="remember" option_label="企業コード・メールアドレスを保存"></form-checkbox>
                </div>
            </div>
            <div class="text-center">
                <button type="submit" class="btn btn-primary btn-lg">ログイン</button>
            </div>
        </form>
    </section>
</template>

<script>
import axios from 'axios';
import FormInput from '@/components/forms/FormInput';
import FormCheckbox from '@/components/forms/FormCheckbox';

export default {
    name: 'Signin',
    components: {
        FormInput,
        FormCheckbox,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
    ],
    data() {
        return {
            credential: {
                company_code: this.$store.state.remember.company_code,
                email: this.$store.state.remember.email,
                password: null,
            },
            remember: this.$store.state.remember.remember,
            redirect: this.$route.query.redirect,
        }
    },
    mounted() {
        let code = this.$route.query.code;
        if (code) {
            this.signinGoogle(code);
        }
    },
    methods: {
        signin() {
            this.startScreenLoading();
            // トークンがないので生 axios を使用
            axios.post(process.env.VUE_APP_API_URI + '/signin', this.credential)
            .then((response) => {
                this.$store.commit('auth/setAccessToken', response.data);
                if (this.remember) {
                    this.$store.commit('remember/remember', this.credential);
                } else {
                    this.$store.commit('remember/forget', this.credential);
                }
                if (this.$route.query.redirect) {
                    this.$router.push(this.$route.query.redirect);
                } else {
                    this.$router.push('/');
                }
            })
            .catch((error) => {
                // ここは $http 使っていないので、自力でメッセージ表示が必要
                if (error.response && error.response.status === 422) {
                    this.showErrorMessage('ログインに失敗しました');

                } else if (error.response && error.response.data && error.response.data.message) {
                    this.showErrorMessage(error.response.data.message);
                    console.log(error.response.data)

                } else {
                    this.showErrorMessage('エラーが発生しました');
                    console.log(error);
                }
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
    }
}
</script>

<style scoped>

</style>

export const pageProfit = {
    namespaced: true,
    state: {
        orderby: 'project',
        order: 'asc',
    },
    mutations: {
        setOrderBy(state, orderby) {
            state.orderby = orderby ?? 'project';
        },
        setOrder(state, order) {
            state.order = order ?? 'asc';
        },
    },
}

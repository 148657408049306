import Enum from './enum'

/**
 * 権限
 */
class Permission extends Enum {
    static VIEW_OTHERS_REPORT = 11; // 他人の日報を閲覧
    static EDIT_OTHERS_REPORT = 12; // 他人の日報を編集
    static VIEW_PROFIT = 21; // 収支を閲覧
    static EDIT_PROFIT = 22; // 収支を編集
    static EXPORT_CSV = 61; // CSVエクスポート
    static IMPORT_CSV = 62; // CSVインポート
    static MANAGE_MASTER = 71; // マスタ管理
    static MANAGE_USER = 81; // ユーザ管理
    static EDIT_COMPANY = 82; // 会社情報設定
    static PAY = 91; // 支払

    static values() {
        return {
            [this.VIEW_OTHERS_REPORT]: '他人の日報を閲覧',
            [this.EDIT_OTHERS_REPORT]: '他人の日報を編集',
            [this.VIEW_PROFIT]: '収支を閲覧',
            [this.EDIT_PROFIT]: '収支を編集',
            [this.EXPORT_CSV]: 'CSVエクスポート',
            [this.IMPORT_CSV]: 'CSVインポート',
            [this.MANAGE_MASTER]: 'マスタ管理',
            [this.MANAGE_USER]: 'ユーザ管理',
            [this.EDIT_COMPANY]: '会社情報設定',
            [this.PAY]: '支払',
        }
    }
}

export default Permission;

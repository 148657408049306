import { thisMonth } from '@/utilities/month'

export const condition = {
    namespaced: true,
    state: {
        project: null, // オブジェクトではなくID
        department: null, // 同上
        user: null, // 同上
        customer: null, // 同上
        billing_customer: null, // 同上
        month: thisMonth(),
        month_from: thisMonth(),
        month_to: thisMonth(),
    },
    mutations: {
        setProject(state, value) {
            state.project = value ?? null;
        },
        setDepartment(state, value) {
            state.department = value ?? null;
        },
        setUser(state, value) {
            state.user = value ?? null;
        },
        setCustomer(state, value) {
            state.customer = value ?? null;
        },
        setBillingCustomer(state, value) {
            state.billing_customer = value ?? null;
        },
        setMonth(state, value) {
            state.month = value ?? thisMonth();
        },
        setMonthFrom(state, value) {
            state.month_from = value ?? thisMonth();
        },
        setMonthTo(state, value) {
            state.month_to = value ?? thisMonth();
        },
    },
}

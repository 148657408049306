<template>
    <nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-primary mb-3">
        <div class="container-fluid">
            <router-link :to="{name: 'Home'}" class="navbar-brand ps-3"><img src="/assets/logo-white.png" title="ヒト✕ツキ" class="navbar-logo"></router-link>
            <template v-if="$store.state.auth && $store.state.auth.user_id">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <router-link v-if="$store.getters['auth/canViewOthersReport']()" :to="{name: 'Report'}" class="nav-link"><i class="bi bi-journal-text"></i> 日報</router-link>
                            <a v-else class="nav-link disabled" href="#" title="権限がありません"><i class="bi bi-journal-text"></i> 案件</a>
                        </li>
                        <li class="nav-item dropdown">
                            <a
                              v-if="$store.getters['auth/canViewOthersReport']()"
                              class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                              data-bs-toggle="dropdown" aria-expanded="false"
                            >
                                <i class="bi bi-people-fill"></i> 稼働
                            </a>
                            <a v-else class="nav-link disabled" href="#" title="権限がありません"><i class="bi bi-people-fill"></i> 稼働</a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><router-link :to="{name: 'Work'}" class="dropdown-item">稼働集計</router-link></li>
                                <li><router-link :to="{name: 'WorkDetail'}" class="dropdown-item">稼働明細</router-link></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <router-link v-if="$store.getters['auth/canViewProfit']()" :to="{name: 'Profit'}" class="nav-link"><i class="bi bi-calculator-fill"></i> 収支</router-link>
                            <a v-else class="nav-link disabled" href="#" title="権限がありません"><i class="bi bi-calculator-fill"></i> 収支</a>
                        </li>
                        <li class="nav-item dropdown">
                            <a
                              v-if="$store.getters['auth/canViewProfit']()"
                              class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                              data-bs-toggle="dropdown" aria-expanded="false"
                            >
                                <i class="bi bi-bar-chart-line-fill"></i> レポート
                            </a>
                            <a v-else class="nav-link disabled" href="#" title="権限がありません"><i class="bi bi-bar-chart-line-fill"></i> レポート</a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><router-link :to="{name: 'ProfitStatsMonthly'}" class="dropdown-item">月別収支レポート</router-link></li>
                                <li><router-link :to="{name: 'ProfitStatsProject'}" class="dropdown-item">案件別収支レポート</router-link></li>
                                <li><router-link :to="{name: 'StatsCustomer'}" class="dropdown-item">顧客別収支レポート</router-link></li>
                                <li><router-link :to="{name: 'StatsProductivity'}" class="dropdown-item">生産性レポート</router-link></li>
                                <li><router-link :to="{name: 'StatsTransition'}" class="dropdown-item">売上粗利推移レポート</router-link></li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a
                              v-if="$store.getters['auth/canManageMaster']()"
                              class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                              data-bs-toggle="dropdown" aria-expanded="false"
                            >
                                <i class="bi bi-list-ul"></i> マスタ
                            </a>
                            <a v-else class="nav-link disabled" href="#" title="権限がありません"><i class="bi bi-list-ul"></i> マスタ</a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><router-link :to="{name: 'MasterProject'}" class="dropdown-item">案件</router-link></li>
                                <li><router-link :to="{name: 'MasterCustomer'}" class="dropdown-item">顧客</router-link></li>
                                <li><router-link :to="{name: 'MasterCategory'}" class="dropdown-item">案件分類</router-link></li>
                                <li><router-link :to="{name: 'MasterSubcontractor'}" class="dropdown-item">外注先</router-link></li>
                                <li><router-link :to="{name: 'MasterDepartment'}" class="dropdown-item">部門</router-link></li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="bi bi-gear"></i> システム
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li v-if="$store.getters['auth/canManageUser']()"><router-link :to="{name: 'SystemUser'}" class="dropdown-item">ユーザ</router-link></li>
                                <li v-else><a class="dropdown-item disabled">ユーザ</a></li>
                                <li v-if="$store.getters['auth/canEditCompany']()"><router-link :to="{name: 'SystemCompany'}" class="dropdown-item">会社設定</router-link></li>
                                <li v-else><a class="dropdown-item disabled">会社設定</a></li>
                                <li v-if="$store.getters['auth/canImportCsv']()"><router-link :to="{name: 'Import'}" class="dropdown-item">インポート</router-link></li>
                                <li v-else><a class="dropdown-item disabled">インポート</a></li>
                                <li v-if="$store.getters['auth/canExportCsv']()"><router-link :to="{name: 'Export'}" class="dropdown-item">エクスポート</router-link></li>
                                <li v-else><a class="dropdown-item disabled">エクスポート</a></li>
                                <li v-if="$store.getters['auth/canPay']()"><router-link :to="{name: 'SystemPayment'}" class="dropdown-item">お支払い</router-link></li>
                                <li v-else><a class="dropdown-item disabled">お支払い</a></li>
                            </ul>
                        </li>
                    </ul>
                    <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <a href="https://github.com/ad5-inc/hitotsuki-guide" target="_blank" class="nav-link"><i class="bi bi-question-circle"></i> ヘルプ</a>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="bi bi-person-circle"></i> {{ user_name }} さん
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><router-link :to="{name: 'Account'}" class="dropdown-item">アカウント</router-link></li>
                                <li><a class="dropdown-item" @click="signOut()">ログアウト</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </template>
        </div>
    </nav>

    <div class="container container-main">
        <router-view/>
    </div>

    <screen-loader v-if="loading > 0"></screen-loader>

    <message-dialog ref="message_dialog" :title="dialog.title" :message="dialog.message" :icon="dialog.icon"></message-dialog>
</template>

<script>
import ScreenLoader from '@/components/tools/ScreenLoader.vue'
import MessageDialog from '@/components/tools/MessageDialog.vue'

export default {
    name: 'App',
    components: {
        ScreenLoader,
        MessageDialog,
    },
    provide() {
        // 参考 emit/props vs provide/inject
        // https://cloudsmith.co.jp/blog/frontend/2020/12/1656030.html
        return {
            startScreenLoading: this.startScreenLoading,
            endScreenLoading: this.endScreenLoading,
            quitScreenLoading: this.quitScreenLoading,
            showMessage: this.showMessage,
            showErrorMessage: this.showErrorMessage,
        }
    },
    data() {
        return {
            loading: 0,

            dialog: {
                title: null,
                message: null,
                icon: null
            },

            //検索オプション
            condition: {
                keyword: null,
                page: 1,
            },
        }
    },
    mounted() {
        window.addEventListener('show_message', (event) => {
            this.showMessage(event.detail.title, event.detail.message);
        });
        window.addEventListener('show_error_message', (event) => {
            this.showErrorMessage(event.detail.title, event.detail.message);
        });
    },
    methods: {
        signOut() {
            this.$store.commit('auth/signOut') //ログイン情報削除
            this.$router.push({name: 'Signin'});
        },
        startScreenLoading() {
            this.loading++;
        },
        endScreenLoading() {
            this.loading--;
        },
        quitScreenLoading() {
            this.loading = 0;
        },
        showMessage(title, message) {
            this.dialog.title = title ?? '完了';
            this.dialog.message = message;
            this.dialog.icon = 'success';
            this.$refs.message_dialog.show();
        },
        showErrorMessage(title, message) {
            this.dialog.title = title ?? 'エラー';
            this.dialog.message = message;
            this.dialog.icon = 'error';
            this.$refs.message_dialog.show();
        },
        //検索
        search(page = 1) {
            this.condition.page = page;

            //検索条件を保存
            this.$store.commit('condition/setSearchCondition', {
                page: 'ProductGroupList',
                condition: this.condition
            });

            //製品一覧ページ内でも機能させるため（同一ページ遷移）queryを付ける
            let today = new Date();
            this.$router.push({name: 'ProductGroupList', query: {date: today.getTime()}});
        },
    },
    computed: {
        user_name() {
            return this.$store.state.auth.user_name;
        }
    },
}
</script>

<style>
.container {
    /* background: #FFF; */
    padding-top: 1em;
    padding-bottom: 1em;
}
.modal-dialog {margin: 10% auto !important;}
</style>

import { createRouter, createWebHistory } from 'vue-router'
import PageSignin from '../pages/PageSignin.vue'
import store from '../store/index.js'

const routes = [
    {
        //ログイン
        path: '/signin',
        name: 'Signin',
        meta: { for_guest: true },
        component: PageSignin
    },
    {
        //ログイン
        path: '/signup',
        name: 'Signup',
        meta: { for_guest: true },
        component: () => import('../pages/PageSignup.vue')
    },
    {
        //ダッシュボード
        path: '/',
        name: 'Home',
        component: () => import('../pages/PageHome.vue')
    },
    {
        //日報一覧
        path: '/report',
        name: 'Report',
        component: () => import('../pages/PageReport.vue')
    },
    {
        //日報登録
        path: '/report/add',
        name: 'ReportAdd',
        component: () => import('../pages/PageReportAdd.vue')
    },
    {
        //日報編集
        path: '/report/:report/edit',
        name: 'ReportEdit',
        component: () => import('../pages/PageReportEdit.vue')
    },
    {
        // 収支一覧（月別）
        path: '/profit',
        name: 'Profit',
        component: () => import('../pages/PageProfit.vue')
    },
    {
        // 収支一覧（案件別）
        path: '/profit/project',
        name: 'ProfitProject',
        component: () => import('../pages/PageProfitProject.vue')
    },
    {
        // 収支詳細/入力
        path: '/profit/:profit',
        name: 'ProfitEdit',
        component: () => import('../pages/PageProfitEdit.vue')
    },
    {
        // 月別収支集計
        path: '/profit/stats/monthly',
        name: 'ProfitStatsMonthly',
        component: () => import('../pages/PageProfitStatsMonthly.vue')
    },
    {
        // 案件別収支集計
        path: '/profit/stats/project/',
        name: 'ProfitStatsProject',
        component: () => import('../pages/PageProfitStatsProject.vue')
    },
    {
        // 案件収支詳細
        path: '/profit/stats/project/:project',
        name: 'ProfitStatsProjectDetail',
        component: () => import('../pages/PageProfitStatsProjectDetail.vue')
    },
    {
        //稼働集計
        path: '/work',
        name: 'Work',
        component: () => import('../pages/PageWork.vue')
    },
    {
        //稼働明細
        path: '/work/detail',
        name: 'WorkDetail',
        component: () => import('../pages/PageWorkDetail.vue')
    },
    {
        //顧客別レポート
        path: '/stats/customer',
        name: 'StatsCustomer',
        component: () => import('../pages/PageStatsCustomer.vue')
    },
    {
        //生産性レポート
        path: '/stats/productivity',
        name: 'StatsProductivity',
        component: () => import('../pages/PageStatsProductivity.vue')
    },
    {
        //売上粗利推移レポート
        path: '/stats/transition',
        name: 'StatsTransition',
        component: () => import('../pages/PageStatsTransition.vue')
    },
    {
        //案件マスタ
        path: '/master/project/',
        name: 'MasterProject',
        meta: { gate: 'canManageMaster' },
        component: () => import('../pages/PageMasterProject.vue')
    },
    {
        //案件マスタ登録
        path: '/master/project/add',
        name: 'MasterProjectAdd',
        meta: { gate: 'canManageMaster' },
        component: () => import('../pages/PageMasterProjectAdd.vue')
    },
    {
        //案件マスタ編集
        path: '/master/project/:project',
        name: 'MasterProjectEdit',
        meta: { gate: 'canManageMaster' },
        component: () => import('../pages/PageMasterProjectEdit.vue')
    },
    {
        //顧客マスタ
        path: '/master/customer/',
        name: 'MasterCustomer',
        meta: { gate: 'canManageMaster' },
        component: () => import('../pages/PageMasterCustomer.vue')
    },
    {
        //外注先マスタ
        path: '/master/category/',
        name: 'MasterCategory',
        meta: { gate: 'canManageMaster' },
        component: () => import('../pages/PageMasterCategory.vue')
    },
    {
        //外注先マスタ
        path: '/master/subcontractor/',
        name: 'MasterSubcontractor',
        meta: { gate: 'canManageMaster' },
        component: () => import('../pages/PageMasterSubcontractor.vue')
    },
    {
        //部門マスタ
        path: '/master/department/',
        name: 'MasterDepartment',
        meta: { gate: 'canManageMaster' },
        component: () => import('../pages/PageMasterDepartment.vue')
    },
    {
        //エクスポート
        path: '/export',
        name: 'Export',
        meta: { gate: 'canExportCsv' },
        component: () => import('../pages/PageExport.vue')
    },
    {
        //インポート
        path: '/import',
        name: 'Import',
        meta: { gate: 'canImportCsv' },
        component: () => import('../pages/PageImport.vue')
    },
    {
        //インポート履歴
        path: '/import/history',
        name: 'ImportHistory',
        meta: { gate: 'canImportCsv' },
        component: () => import('../pages/PageImportHistory.vue')
    },
    {
        // ユーザ管理
        path: '/system/user',
        name: 'SystemUser',
        meta: { gate: 'canManageUser' },
        component: () => import('../pages/PageSystemUser.vue')
    },
    {
        // ユーザ追加
        path: '/system/user/add',
        name: 'SystemUserAdd',
        meta: { gate: 'canManageUser' },
        component: () => import('../pages/PageSystemUserAdd.vue')
    },
    {
        // ユーザ管理編集
        path: '/system/user/:user',
        name: 'SystemUserEdit',
        meta: { gate: 'canManageUser' },
        component: () => import('../pages/PageSystemUserEdit.vue')
    },
    {
        // ユーザ標準単価管理
        path: '/system/user/:user/unit',
        name: 'SystemUserLaborCost',
        meta: { gate: 'canManageUser' },
        component: () => import('../pages/PageSystemUserLaborCost.vue')
    },
    {
        // 会社情報
        path: '/system/company',
        name: 'SystemCompany',
        meta: { gate: 'canEditCompany' },
        component: () => import('../pages/PageSystemCompany.vue')
    },
    {
        // 支払
        path: '/system/payment',
        name: 'SystemPayment',
        component: () => import('../pages/PageSystemPayment.vue')
    },
    {
        // アカウント
        path: '/account',
        name: 'Account',
        component: () => import('../pages/PageAccount.vue')
    },


    /**
     * エラー系
     */
    {
        //403ページ
        path: '/error/403',
        name: 'Error403',
        component: () => import('../pages/error/PageError403.vue')
    },
    {
        //404ページ ＝ 上記のいずれにもマッチしないもの
        path: '/:pathMatch(.*)*',
        name: 'Error404',
        component: () => import('../pages/error/PageError404.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.for_guest) {
        //ログインの不要な画面
        next();
        return;
    }

    //ログイン切れ
    if (!store.state.auth.access_token) {
        next({ path: '/signin', query: { redirect: to.fullPath } });
        return;
    }

    //仮パスワード
    if (store.state.auth.is_password_temporary && to.name !== 'Account') {
        next({ name: 'Account' });
        return;
    }

    //権限なし
    if (to.meta && to.meta.gate) {
        if (false === store.getters['auth/' + to.meta.gate]()) {
            next({ path: '/error/403' });
            return;
        }
    }

    next();
});

export default router

// 年月の扱い (システム内では数値6桁)

export function displayMonth(value) {
    if (typeof value !== 'number') {
        return "";
    }

    return Math.floor(value / 100) + "年" + (value % 100) + "月";
}

export function thisMonth() {
    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth() + 1

    return year * 100 + month;
}

export function startOfPeriod(settlement_month) {
    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth() + 1

    if (settlement_month === 12) {
        month = 1;
    } else if (month > settlement_month) {
        month = settlement_month + 1;
    }  else {
        year = year - 1;
        month = settlement_month + 1;
    }

    return year * 100 + month;
}

export function endOfPeriod(settlement_month) {
    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth() + 1

    if (month > settlement_month) {
        year = year + 1;
        month = settlement_month;
    } else {
        month = settlement_month;
    }

    return year * 100 + month;
}

export function addMonth(value, diff = 1) {
    let year = Math.floor(value / 100);
    let month = (value % 100);

    month = month + diff;

    while (month < 1 || month > 12) {
        if (month < 1) {
            month = month + 12;
            year = year - 1;
        } else if (month > 12) {
            month = month - 12;
            year = year + 1;
        }
    }

    return year * 100 + month;
}

export function subMonth(value, diff = 1) {
    return addMonth(value, diff * -1);
}

export function datesOf(value) {
    let year = Math.floor(value / 100);
    let month = (value % 100);

    let last_date = new Date(year, month, 0); // 翌月の0日＝当月の末日 / 月は本来 month - 1 なので、month は翌月を差す
    let last_day = last_date.getDate();

    let dates = [];
    for (let day = 1; day <= last_day; day++) {
        dates.push(year + "-" + ('0' + month).slice(-2) + "-" + ('0' + day).slice(-2));
    }
    return dates;
}

export function calendarOf(value) {
    let year = Math.floor(value / 100);
    let month = (value % 100);

    let first_date = new Date(year, month - 1, 1);
    let start_buffer = (first_date.getDay() + 6) % 7 // 最初に作る空白日の数
    let last_date = new Date(year, month, 0);
    let end_buffer = (7 - last_date.getDay()) % 7; // 最後に作る空白日の数
    let last_day = last_date.getDate(); // 最終日の D

    let calendar = [];
    let dates = [];
    for (let i = 0; i < start_buffer; i++) {
        dates.push(null);
    }
    for (let day = 1; day <= last_day; day++) {
        dates.push(year + "-" + ('0' + month).slice(-2) + "-" + ('0' + day).slice(-2));
        if (dates.length === 7) {
            calendar.push(dates);
            dates = [];
        }
    }
    for (let i = 0; i < end_buffer; i++) {
        dates.push(null);
    }
    calendar.push(dates);

    return calendar;
}

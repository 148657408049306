export const pageMaster = {
    namespaced: true,
    state: {
        keyword: null,
        is_active: 1,
    },
    mutations: {
        setKeyword(state, keyword) {
            state.keyword = keyword;
        },
        setIsActive(state, is_active) {
            state.is_active = is_active;
        },
    },
}

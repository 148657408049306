// 数値系

// 数値3桁区切り
export function numberFormat(value) {
    if (value === null || typeof value === 'undefined') {
        return null;
    }

    // 整数でない場合（小数点）
    if (!Number.isInteger(value)) {
        // 数値変換
        return Number(value).toLocaleString(undefined, { maximumFractionDigits: 20 });
    }

    // 数値変換
    return Number(value).toLocaleString();
}

// 分を HH:MM 表記に
export function clock(value) {
    if (value === null || typeof value === 'undefined') {
        return null;
    }

    return Math.floor(value / 60) + ":" + ("0" + Math.floor(value % 60)).slice(-2);
}
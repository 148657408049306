import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";
import { auth } from './auth.js';
import { condition } from './condition.js';
import { remember } from './remember.js';
import { pageProfit } from './page-profit.js';
import { pageMaster } from './page-master.js';

const store = createStore({
    modules: {
        auth,
        condition,
        remember,
        pageProfit,
        pageMaster,
    },
    plugins: [
        createPersistedState({
            key: 'drops',
            paths: ['auth', 'condition', 'remember', 'pageProfit'],
        })
    ]
})

export default store

import axios from 'axios'
import store from '@/store';
import router from '@/router';
//import { now } from '@/utilities/date-format';

const http = axios.create({
    baseURL: process.env.VUE_APP_API_URI
})

http.interceptors.request.use(
    async (config) => {
        //if (store.state.auth.access_token_expired < now()) {
        //    //トークンのリフレッシュ
        //    await axios.post(process.env.VUE_APP_API_URI + '/signin/refresh', {
        //        refresh_token: store.state.auth.refresh_token
        //    }).then((response) => {
        //        store.commit('auth/setAccessToken', response.data);
        //    }).catch(() => {
        //        //敢えて捕捉せず、メインリクエストで401にさせる
        //    });
        //}

        config.headers = {
            Authorization: 'Bearer ' + store.state.auth.access_token,
            'X-Requested-With': "XMLHttpRequest",
        }
        return config;
    }
)

http.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response && error.response.status === 400) {
            let message_detail = {
                title: 'エラー',
                message: error.response.data.message,
                icon: 'error'
            }
            let event = new CustomEvent('show_error_message', {detail: message_detail});
            window.dispatchEvent(event);

        } else if (error.response && error.response.status === 401) {
            let message_detail = {
                title: 'ログイン切れ',
                message: 'ログインの有効期間が切れました。ログイン画面に移動します',
                icon: 'error'
            }
            let event = new CustomEvent('show_error_message', {detail: message_detail});
            window.dispatchEvent(event);

            store.commit('auth/signOut'); //ログイン情報削除
            store.commit('condition/signOut') //検索条件削除
            router.push('/signin');

        } else if (error.response && error.response.status === 403) {
            let message_detail = {
                title: '権限エラー',
                message: 'この操作をする権限がありません。必要な場合、管理者に権限の変更を依頼してください。',
                icon: 'error'
            }
            let event = new CustomEvent('show_error_message', {detail: message_detail});
            window.dispatchEvent(event);

        } else if (error.response && error.response.status === 404) {
            let message_detail = {
                title: '404エラー',
                message: 'データが見つかりません',
                icon: 'error'
            }
            let event = new CustomEvent('show_error_message', {detail: message_detail});
            window.dispatchEvent(event);

        } else if (error.response && error.response.status === 418) {
            let message_detail = {
                title: '有効期限切れ',
                message: 'ページを再読み込みしてください。',
                icon: 'error'
            }
            let event = new CustomEvent('show_error_message', {detail: message_detail});
            window.dispatchEvent(event);

        } else if (error.response && error.response.status === 422) {
            let errors = error.response.data.errors;
            let errorMessages = [];
            Object.keys(errors).forEach((key) => {
                let array_order = key.match(new RegExp(/\.([0-9]+)\./));
                if (array_order) {
                    //ネストしている場合は何行目が間違っているか表示する（多重ネストは非対応です）
                    let row_number = parseInt(array_order[1]) + 1;
                    errorMessages.push(`${row_number}行目の${errors[key]}`);
                } else {
                    errorMessages.push(errors[key]);
                }
            });

            let message_detail = {
                title: '入力内容に誤りがあります',
                message: errorMessages.join("\n"),
                icon: 'error'
            }
            let event = new CustomEvent('show_error_message', {detail: message_detail});
            window.dispatchEvent(event);

        } else {
            let message_detail = {
                title: 'エラー',
                message: 'システムエラーが発生しました',
                icon: 'error'
            }
            let event = new CustomEvent('show_error_message', {detail: message_detail});
            window.dispatchEvent(event);
        }

        return Promise.reject(error);
    }
)

export default http

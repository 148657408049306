export const remember = {
    namespaced: true,
    state: {
        company_code: null,
        email: null,
        remember: 0,
    },
    mutations: {
        remember(state, value) {
            state.company_code = value.company_code;
            state.email = value.email;
            state.remember = 1;
        },
        forget(state) {
            state.company_code = null;
            state.email = null;
            state.remember = 0;
        },
    },
}

<template>
    <input
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @change="$emit('change')"
        :type="type"
        :required="required"
        :disabled="disabled"
        :placeholder="placeholder"
        :pattern="pattern"
        :title="title"
        class="form-control"
        :class="{[optional_class]: true}"
    >
</template>

<script>
export default {
    name: 'FormInput',
    props: {
        modelValue: {
            type: String
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'text'
        },
        placeholder: {
            type: String,
            default: ''
        },
        pattern: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        optional_class: {
            type: String,
            default: ''
        },
    },
    emits: [
        'update:modelValue',
        'change',
    ],
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

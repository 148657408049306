import Permission from "@/models/enums/permission";

export const auth = {
    namespaced: true,
    state: {
        user_id: null,
        company: null,
        email: null,
        is_password_temporary: null,
        user_name: null,
        permissions: [],
        access_token: null,
        access_token_issued: null,
        access_token_expired: null,
    },
    mutations: {
        //ログイン時処理
        setAccessToken (state, param) {
            state.user_id = param.user_id;
            state.company = param.company;
            state.email = param.email;
            state.is_password_temporary = param.is_password_temporary;
            state.user_name = param.user_name;
            state.permissions = param.permissions ?? [];
            state.access_token = param.access_token;
            state.access_token_issued = param.access_token_issued;
            state.access_token_expired = param.access_token_expired;
        },
        //ログアウト時処理
        signOut (state) {
            state.user_id = null;
            state.company = null;
            state.email = null;
            state.is_password_temporary = null;
            state.user_name = null;
            state.permissions = [];
            state.access_token = null;
            state.access_token_issued = null;
            state.access_token_expired = null;
        },
    },
    getters: {
        // ユーザ管理権限
        // バックエンドの AuthServiceProvider も一緒に修正すること
        canViewOthersReport: (state) => () => {
            return state.permissions.includes(Permission.VIEW_OTHERS_REPORT);
        },
        canEditOthersReport: (state) => () => {
            return state.permissions.includes(Permission.EDIT_OTHERS_REPORT);
        },
        canViewProfit: (state) => () => {
            return state.permissions.includes(Permission.VIEW_PROFIT);
        },
        canEditProfit: (state) => () => {
            return state.permissions.includes(Permission.EDIT_PROFIT);
        },
        canExportCsv: (state) => () => {
            return state.permissions.includes(Permission.EXPORT_CSV);
        },
        canImportCsv: (state) => () => {
            return state.permissions.includes(Permission.IMPORT_CSV);
        },
        canManageMaster: (state) => () => {
            return state.permissions.includes(Permission.MANAGE_MASTER);
        },
        canManageUser: (state) => () => {
            return state.permissions.includes(Permission.MANAGE_USER);
        },
        canEditCompany: (state) => () => {
            return state.permissions.includes(Permission.EDIT_COMPANY);
        },
        canPay: (state) => () => {
            return state.permissions.includes(Permission.PAY);
        },
    },
}
